#lecturenotes #main2 .col-3 {
    width: 24%;
    display: block;
    height: 400px;
    text-align: left;
    align-content: unset;
    justify-self: center;
    justify-items: center;
    background-color: white;
    border-radius: 10px;
    justify-items: start;
}
#lecturenotes .col-3 img {
    width: 100%;
    height: 50%;
    border-radius: 0px;
}
#lecturenotes .col-3 strong, #lecturenotes .col-3 p {
    padding: 5px 10px;
    font-family: Nunito sans;
}
#lecturenotes .col-3 strong {
    font-weight: 700;
    font-size: 20px;
}
#lecturenotes .col-3 p {
    font-weight: 450;
    margin-top: 0px;
    margin-bottom: 0px;
}
form label {
    font-family: Nunito sans;
    font-weight: 600;
}
form input {
    font-size: 20px;
}
form textarea {
    font-size: 20px;
}