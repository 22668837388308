#loginmain {
    background-color: #dedbdb;
    padding: 5% 5%;
}
#logincard {
    display: grid;
    background-color: white;
    padding: 3% 2%;
    width: 30%;
    margin: auto;
    font-family: Nunito Sans;
    border-radius: 10px;
}
#logincard h2 {
    margin: 0px;
}
#logincard form {
    display: grid;
}
#logincard label {
    font-size: 18px;
    margin-bottom: 0.5%;
}
#logincard input {
    height: 40px;
    margin-bottom: 2%;
    border-radius: 10px;
}
#logincard button {
    background-color: #308A11;
    color: white;
    padding: 2%;
    text-align: center;
    border: none;
    font-weight: 500;
    font-size: 20px;
    border-radius: 10px;
}
#logincard button img {
    padding: 0px 2%;
}